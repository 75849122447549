<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="code" placeholder="请输入事件单号" />
            <a-input v-model:value="eventName" placeholder="请输入事件名称" />
            <Select
                v-model:value="excType"
                :options="excTypeOptions"
                placeholder="请选择异常类型"
            />
            <a-input v-model:value="workOrderCode" placeholder="请输入关联工单号" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Select from "@/components/Select";
export default defineComponent({
    props: ["excTypeOptions"],
    emits: ["search"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const params = reactive({
            code: undefined,
            eventName: undefined,
            excType: undefined,
            workOrderCode: undefined,
        });
        const reset = () => {
            params.code = undefined;
            params.eventName = undefined;
            params.excType = undefined;
            params.workOrderCode = undefined;
            search();
        };
        const search = () => {
            let { code, eventName, workOrderCode } = params;
            params.code = code?.trim();
            params.eventName = eventName?.trim();
            params.workOrderCode = workOrderCode?.trim();
            emit("search", params);
        };
        return {
            ...toRefs(params),
            search,
            reset,
        };
    },
});
</script>
<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>