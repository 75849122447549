<template>
    <WorkOrderList
        v-model:workOrderListVisible="workOrderListVisible"
        @onSelect="onSelectWorkOrder"
    />
    <a-modal
        v-model:visible="visible"
        title="事件上报"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="700px"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="事件名称"
                name="eventName"
                :rules="[
                    { required: true, message: '请输入事件名称' },
                    { validator: checkSpaceStartEnd },
                ]"
            >
                <a-input v-model:value="formData.eventName" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="关联工单"
                name="workOrderCode"
                :rules="[{ required: !formData.relatedWorkOrder, message: '请选择生产工单号' }]"
            >
                <div class="contant-item">
                    <SelectInput
                        :title="formData.workOrderCode"
                        :onClick="handleOpenWorkOrderList"
                        :disabled="formData.relatedWorkOrder"
                        :showDeleteIcon="false"
                        style="width: 320px; top: -5px"
                    ></SelectInput>
                    <a-checkbox
                        v-model:checked="formData.relatedWorkOrder"
                        :disabled="Boolean(upDateId)"
                    >
                        不关联工单
                    </a-checkbox>
                </div>
            </a-form-item>

            <a-form-item
                label="异常类型"
                name="excType"
                :rules="[{ required: true, message: '请选择异常类型' }]"
            >
                <Select v-model:value="formData.excType" :options="excTypeOptions" />
            </a-form-item>

            <a-form-item
                label="事件描述"
                name="description"
                :rules="[
                    { required: true, message: '请输入事件描述' },
                    { validator: checkSpaceStartEnd },
                ]"
            >
                <a-textarea
                    v-model:value="formData.description"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item name="eventFileIds">
                <template v-slot:label>事件图片({{ formData.eventFileIds.length }}/12)</template>
                <Upload
                    businessType="mes_exc_report_event_pic"
                    @onSuccess="ids => (formData.eventFileIds = ids)"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiAbnormalEventReport } from "@/api";
import Select from "@/components/Select";
import WorkOrderList from "./workOrderList";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import dayjs from "dayjs";
import Upload from "@/components/Upload";
import SelectInput from "@/components/SelectInput";
export default defineComponent({
    props: ["incidentReportVisible", "excTypeOptions"],
    emits: ["onReportSuccess", "update:incidentReportVisible"],
    components: {
        Select,
        WorkOrderList,
        DownOutlined,
        Upload,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            workOrderListVisible: false,
            formRef: null,
            formData: {
                eventName: "",
                relatedWorkOrder: false,
                workOrderCode: "",
                excType: "",
                description: "",
                eventFileIds: [],
            },
        });
        const handleCancel = () => {
            state.formRef.resetFields();
            state.visible = false;
            emit("update:incidentReportVisible", null);
        };
        const onSelectWorkOrder = code => {
            state.formData.workOrderCode = code;
        };
        const onFinish = async () => {
            let { relatedWorkOrder } = state.formData;
            let res = await apiAbnormalEventReport({
                ...state.formData,
                relatedWorkOrder: !relatedWorkOrder,
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("onReportSuccess");
            }
        };
        const checkSpaceStartEnd = (rule, value) => {
            if (value && !checkValueIsAvailable(value, "spaceStartEnd")) {
                return Promise.reject("不能以空格开头或结尾");
            } else {
                return Promise.resolve();
            }
        };

        watch(
            () => state.formData.relatedWorkOrder,
            newVal => {
                if (newVal && !props.upDateId) {
                    state.formData.workOrderCode = "";
                }
            }
        );

        watch(
            () => props.incidentReportVisible,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );
        const disabledStartDate = current => {
            const { scheduledEndDate } = state.formData;
            if (!scheduledEndDate) {
                return false;
            }
            return current && current >= dayjs(scheduledEndDate).endOf("day");
        };
        const disabledEndDate = current => {
            const { scheduledStartDate } = state.formData;
            if (!scheduledStartDate) {
                return false;
            }
            return current && current < dayjs(scheduledStartDate).startOf("day");
        };
        const handleOpenWorkOrderList = () => {
            if (!state.formData.relatedWorkOrder) {
                state.workOrderListVisible = true;
            }
        };
        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkSpaceStartEnd,
            onSelectWorkOrder,
            disabledStartDate,
            disabledEndDate,
            handleOpenWorkOrderList,
        };
    },
});
</script>
<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
.mask {
    position: absolute;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 32px;
    height: 32px;
    border-radius: 2px;
    width: 320px;
    cursor: pointer;
    position: absolute;
    opacity: 0;
}
</style>
Footer
