<template>
    <a-modal
        v-model:visible="visible"
        title="工单列表"
        width="1000px"
        okText="提交"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <Select
            v-model:value="workorderType"
            :options="[
                { label: '集成工单', value: 1 },
                { label: '车间工单', value: 2 },
            ]"
            placeholder="请选择工单类型"
            style="width: 150px; margin-right: 8px"
        />
        <a-input-search
            v-model:value="code"
            placeholder="请输入工单号以查询"
            @search="getTabelData"
            class="search-input"
            enter-button="搜索"
        />
        <a-table
            :columns="columns"
            :data-source="tableData"
            bordered
            size="small"
            :loading="loading"
            :row-selection="{
                selectedRowKeys,
                onChange: onSelectChange,
                type: 'radio',
            }"
            :pagination="pagination"
            @change="paginationChange"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'key'">
                    {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiProductWorkOrderList, apiCJWorkOrderList } from "@/api";
import Select from "@/components/Select";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "key",
        width: 60,
    },
    {
        title: "工单号",
        dataIndex: "code",
    },
    {
        title: "生产订单号",
        dataIndex: "orderCode",
    },
    {
        title: "项目名称",
        dataIndex: "projectName",
    },
];
export default defineComponent({
    props: ["workOrderListVisible"],
    emits: ["update:workOrderListVisible", "select"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            code: "",
            workorderType: 1,
            tableData: [],
            selectedRowKeys: [],
            selectedRows: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 15,
                showSizeChanger: false,
            },
        });
        const handleCancel = () => {
            state.visible = false;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            emit("update:workOrderListVisible", false);
        };
        const getTabelData = async () => {
            if (!state.workorderType) return showMessage("info", "请选择工单类型");
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
                code,
            } = state;
            const fn = state.workorderType == 1 ? apiProductWorkOrderList : apiCJWorkOrderList;
            let res = await fn({
                pageNum,
                pageSize,
                queryParam: {
                    code: code?.trim(),
                    status: "PRODUCING",
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.tableData = res.data.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };
        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };
        const onSelectChange = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };
        const handleSubmit = () => {
            if (!state.selectedRows.length) {
                return showMessage("info", "请先选择工单");
            }
            let [{ code }] = state.selectedRows;
            emit("onSelect", code);
            handleCancel();
        };
        watch(
            () => props.workOrderListVisible,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getTabelData();
                }
            }
        );
        return {
            columns,
            ...toRefs(state),
            handleCancel,
            paginationChange,
            getTabelData,
            handleSubmit,
            onSelectChange,
        };
    },
});
</script>

<style lang="less" scoped>
.search-input {
    margin-bottom: 16px;
    width: 300px;
}
</style>
Footer
