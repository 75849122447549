<template>
    <div style="display: none" v-if="previewVisible">
        <a-image-preview-group
            :preview="{
                visible: previewVisible,
                onVisibleChange: onVisibleChange,
            }"
        >
            <a-image
                v-for="item in previewPics"
                :key="item"
                :src="`${baseURL}/web/mes-file-info/download/${item}`"
            />
        </a-image-preview-group>
    </div>
    <div class="container">
        <IncidentReport
            v-model:incidentReportVisible="incidentReportVisible"
            :excTypeOptions="excTypeOptions"
            @onReportSuccess="getTabelData"
        />
        <DetailHandle
            v-model:detailId="detailId"
            v-model:handleId="handleId"
            :currentTab="status"
            @onHandleSuccess="getTabelData"
        />
        <a-tabs v-model:activeKey="status" @change="onChangeTab">
            <a-tab-pane key="WAIT_PROCESSED" tab="待处理事件"></a-tab-pane>
            <a-tab-pane key="PROCESSED" tab="已处理事件"></a-tab-pane>
        </a-tabs>
        <FilterBlock @search="search" :excTypeOptions="excTypeOptions" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1500 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'workOrderCode'">
                        {{ record?.workOrderCode || "未关联工单号" }}
                    </template>
                    <template v-if="column.dataIndex === 'eventPicIds'">
                        <a
                            v-if="record?.eventPicIds?.length"
                            @click="handleViewPic(record?.eventPicIds)"
                        >
                            查看({{ record?.eventPicIds?.length }})
                        </a>
                        <span v-else style="color: rgba(0, 0, 0, 0.3)">暂无图片</span>
                    </template>
                    <template v-if="column.dataIndex === 'handlePicIds'">
                        <a
                            v-if="record?.handlePicIds?.length"
                            @click="handleViewPic(record?.handlePicIds)"
                        >
                            查看({{ record?.handlePicIds?.length }})
                        </a>
                        <span v-else style="color: rgba(0, 0, 0, 0.3)">暂无图片</span>
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailId = record.id">详情</a>
                            <a
                                v-perm="['btn:mes-exc-handle-admin', 'btn:mes-exc-handle-dept']"
                                v-show="status !== 'PROCESSED' && record?.hasPerm"
                                @click="() => (handleId = record.id)"
                            >
                                处理
                            </a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button
                            type="primary"
                            v-perm="'btn:mes-exc-report-report'"
                            @click="() => (incidentReportVisible = true)"
                        >
                            事件上报
                        </a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import IncidentReport from "./incidentReport";
import DetailHandle from "./detailHandle";
import { showMessage } from "@/utils/common";
import { apiAbnormalEventList, apiAbnormalEventIndexData } from "@/api";
import { baseURL } from "@/utils/config";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "事件单号",
        dataIndex: "code",
        fixed: "left",
    },
    {
        title: "事件名称",
        dataIndex: "eventName",
        fixed: "left",
    },
    {
        title: "异常类型",
        dataIndex: "excType",
    },
    {
        title: "关联工单号",
        dataIndex: "workOrderCode",
    },
    {
        title: "问题描述",
        dataIndex: "description",
    },
    {
        title: "上报人",
        dataIndex: "creatorDisplayName",
    },
    {
        title: "处理时间",
        dataIndex: "processingTime",
    },
    {
        title: "处理人",
        dataIndex: "processingPersonDisplayName",
    },
    {
        title: "事件图片",
        dataIndex: "eventPicIds",
        width: 100,
    },
    {
        title: "处理图片",
        dataIndex: "handlePicIds",
        width: 100,
    },
    {
        title: "提交时间",
        dataIndex: "createdTime",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 100,
    },
];
export default defineComponent({
    components: {
        FilterBlock,
        IncidentReport,
        DetailHandle,
    },
    setup() {
        const state = reactive({
            status: "WAIT_PROCESSED",
            previewVisible: false,
            previewPics: [],
            excTypeOptions: [],
            incidentReportVisible: false,
            detailId: null,
            handleId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
            columns: computed(() => {
                if (state.status == "WAIT_PROCESSED") {
                    return columns.filter(item => {
                        return ![
                            "handlePicIds",
                            "processingTime",
                            "processingPersonDisplayName",
                        ].includes(item.dataIndex);
                    });
                }
                return columns;
            }),
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiAbnormalEventList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    status: state.status,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const onChangeTab = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const handleOpenDetail = id => {
            state.detailId = id;
        };

        const onVisibleChange = vis => {
            state.previewPics = [];
            state.previewVisible = vis;
        };

        const handleViewPic = pics => {
            state.previewPics = pics;
            state.previewVisible = true;
        };

        const getIndexData = async () => {
            let res = await apiAbnormalEventIndexData();
            if (res.status === "SUCCESS") {
                state.excTypeOptions = res?.data?.excTypeCandidate.map((item, index) => ({
                    label: item,
                    value: item,
                }));
                getTabelData();
            }
        };
        getIndexData();

        return {
            ...toRefs(state),
            search,
            paginationChange,
            onChangeTab,
            handleOpenDetail,
            getTabelData,
            handleViewPic,
            onVisibleChange,
            baseURL,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    // display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
