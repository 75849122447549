<template>
    <a-drawer
        v-model:visible="visible"
        :title="`事件${handleId ? '处理' : '详情'}`"
        @cancel="handleCancel"
        :destroyOnClose="true"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit" @click="handleFinish">
                    {{ handleId ? "提交" : "关闭" }}
                </a-button>
            </a-space>
        </template>
        <a-descriptions title="事件信息" :column="2">
            <a-descriptions-item label="事件单号">{{ infoData?.code }}</a-descriptions-item>
            <a-descriptions-item label="事件名称">{{ infoData?.eventName }}</a-descriptions-item>
            <a-descriptions-item label="关联工单">
                {{ infoData?.relatedWorkOrder ? infoData?.workOrderCode : "未关联工单" }}
            </a-descriptions-item>
            <a-descriptions-item label="异常类型">
                {{ infoData?.excType }}
            </a-descriptions-item>
            <a-descriptions-item label="上报人">
                {{ infoData?.creatorDisplayName }}
            </a-descriptions-item>
            <a-descriptions-item label="提交时间">{{ infoData?.createdTime }}</a-descriptions-item>
        </a-descriptions>
        <a-form
            ref="formRef"
            :model="infoData"
            name="basic"
            autocomplete="off"
            @finish="handleFinish"
        >
            <a-form-item label="事件描述" name="description">
                <a-textarea :value="infoData?.description" disabled :rows="4" :maxlength="300" />
            </a-form-item>

            <a-form-item label="事件图片" name="eventPicIds">
                <a-image-preview-group v-if="infoData?.eventPicIds?.length">
                    <a-image
                        v-for="item in infoData?.eventPicIds"
                        :key="item"
                        :src="`${baseURL}/web/mes-file-info/download/${item}`"
                        :width="100"
                        :height="100"
                    />
                </a-image-preview-group>
            </a-form-item>

            <div v-if="!detailId || currentTab == 'PROCESSED'">
                <div style="color: rgba(0, 0, 0, 0.85); font-weight: bold; font-size: 16px">
                    处理操作
                </div>

                <a-form-item
                    label="处理方案"
                    name="processingScheme"
                    :rules="[
                        { required: Boolean(handleId), message: '请输入处理方案' },
                        { validator: checkSpaceStartEnd },
                    ]"
                >
                    <a-textarea
                        v-model:value="infoData.processingScheme"
                        :disabled="Boolean(detailId)"
                        :rows="4"
                        :maxlength="800"
                        placeholder="最多可输入800字"
                    />
                </a-form-item>

                <a-form-item label="处理图片" name="handlePicIds" v-if="detailId">
                    <a-image-preview-group>
                        <a-image
                            v-for="item in infoData?.handlePicIds"
                            :key="item"
                            :src="`${baseURL}/web/mes-file-info/download/${item}`"
                            :width="100"
                            :height="100"
                        />
                    </a-image-preview-group>
                </a-form-item>
            </div>

            <a-form-item name="handlePicIds" v-if="!detailId">
                <template v-slot:label>处理图片({{ infoData?.handlePicIds.length }}/12)</template>
                <Upload
                    v-if="!detailId"
                    businessType="mes_exc_report_handle_pic"
                    @onSuccess="ids => (infoData.handlePicIds = ids)"
                />
            </a-form-item>

            <!-- <a-form-item :wrapper-col="{ offset: 22, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">
                    {{ handleId ? "提交" : "确定" }}
                </a-button>
            </a-form-item> -->
        </a-form>
    </a-drawer>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiAbnormalEventDetail, apiAbnormalEventHandle } from "@/api";
import Select from "@/components/Select";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { baseURL } from "@/utils/config";
export default defineComponent({
    props: ["detailId", "handleId", "currentTab"],
    emits: ["update:detailId", "update:handleId"],
    components: {
        Select,
        DownOutlined,
        Upload,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            formRef: null,
            infoData: {
                processingScheme: "",
                handlePicIds: [],
            },
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            if (props.detailId) {
                emit("update:detailId", null);
            } else {
                emit("update:handleId", null);
            }
        };

        const handleFinish = () => {
            let id = props.handleId;
            if (id) {
                state.formRef.validate().then(async () => {
                    let { processingScheme, handlePicIds } = state.infoData;
                    let res = await apiAbnormalEventHandle({
                        id,
                        processingScheme,
                        handleFileIds: handlePicIds,
                    });
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        emit("onHandleSuccess");
                        handleCancel();
                    }
                });
            } else {
                handleCancel();
            }
        };

        const getIndexData = async id => {
            let res = await apiAbnormalEventDetail(id);
            if (res.status === "SUCCESS") {
                state.infoData = {
                    ...state.infoData,
                    ...res?.data,
                };
            }
        };

        const checkSpaceStartEnd = (rule, value) => {
            if (value && !checkValueIsAvailable(value, "spaceStartEnd")) {
                return Promise.reject("不能以空格开头或结尾");
            } else {
                return Promise.resolve();
            }
        };

        watch(
            () => [props.detailId, props.handleId],
            ids => {
                const newVal = ids.find(id => id !== null);
                if (newVal) {
                    state.visible = true;
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            handleFinish,
            checkSpaceStartEnd,
            baseURL,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
    background: #d9d9d9;
}
.mask {
    position: absolute;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 32px;
    height: 32px;
    border-radius: 2px;
    width: 320px;
    cursor: pointer;
    position: absolute;
    opacity: 0;
}
</style>
Footer
